import React from 'react';
import {
  FaConciergeBell,
  FaWheelchair,
  FaWineBottle,
  FaUsers,
  FaUserTie,
  FaHandsHelping,
} from 'react-icons/fa';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="logo-slogan-wrapper">
      <div className="logo-wrapper">
        <img
          className="logo"
          src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto/v1565639575/carloslopez/carlos-logo_zux5n7.jpg"
          alt="carlos lopez logo"
        />
      </div>
      <h2 className="slogan">
        The Courthouse Constable
        <br />
        Keeping Families Safe
      </h2>
    </div>
    <div className="home-blurb">
      <img
        className="home-blurb-img"
        src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto/v1566240811/carloslopez/carlos2_vqvyfh.jpg"
        alt="carlos lopez"
      />
      <p className="home-blurb-content">
        <span>Carlos Lopez</span> has been working for Travis County for 45
        years and is in his second term as Travis County Constable. His awards
        include three Deputy Constables of the Year and a Distinguished
        Commander Award from Travis County Constable, Precinct 5. His experience
        as a constable, educator, and mentor will help serve our community at
        the highest level of professionalism.
      </p>
    </div>
    <div className="platform">
      <h2 className="platform-title">Carlos&apos; Commitment</h2>
      <ul>
        <li>
          <FaConciergeBell className="platform-icon" focusable="false" />
          <p>
            Provide <span>quick and effective service</span> of any and all
            court process received
          </p>
        </li>
        <li>
          <FaWineBottle className="platform-icon" focusable="false" />
          <p>
            <span>Reduce underage drinking</span> through education and
            prevention
          </p>
        </li>
        <li>
          <FaHandsHelping className="platform-icon" focusable="false" />
          <p>
            Continue to collaborate to <span>reduce domestic violence</span>
          </p>
        </li>
        <li>
          <FaUserTie className="platform-icon" focusable="false" />
          <p>
            Educate our young men on <span>healthy relationships</span> through
            our Men as Allies program
          </p>
        </li>
        <li>
          <FaWheelchair className="platform-icon" focusable="false" />
          <p>
            Aggressively pursue <span>disabled parking</span> violators
          </p>
        </li>

        <li>
          <FaUsers className="platform-icon" focusable="false" />
          <p>
            Keep our <span>aging and disabled community safe</span> through our
            Silver Watch program
          </p>
        </li>
      </ul>
    </div>
    <div className="program-reverse">
      <h2>Safe Disposal of Pharmaceuticals</h2>

      <div className="program-content">
        <img
          src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto/v1566241469/carloslopez/drug-disposal-2_rmjjjk.jpg"
          alt="safe disposal of pharmaceuticals"
        />
        <p>
          The safe disposal of expired or unused prescription drugs helps keep
          them out of the hands of children and our environment clean. It also
          helps prevent pill abuse and theft by ridding homes of potentially
          dangerous medication. A drop box is located in the Precinct 5 office
          during regular business hours.{' '}
          <a
            className="program-link"
            href="http://constable5.com/inf/unwanted-drugs-disposal.shtml"
            target="_blank"
            rel="noreferrer noopener"
          >
            Learn more...
          </a>
        </p>
      </div>
    </div>
    <div className="program">
      <h2>Reduce Underage Drinking</h2>

      <div className="program-content">
        <img
          src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto/v1569604422/carloslopez/underage-drinking_ydq2sy.jpg"
          alt="reduce underage drinking"
        />

        <p>
          As Texas leads the nation in the number of youth fatalities in
          alcohol-related crashes, Carlos partnered with the Travis County
          Attorney&apos;s Office to train officers to be able to educate
          teenagers in local middle and high schools to help them make better
          decisions when confronted with today&apos;s easy access to alcohol at
          a young age, potentially saving lives.{' '}
          <a
            className="program-link"
            href="http://constable5.com/inf/UDPP.shtml"
            target="_blank"
            rel="noreferrer noopener"
          >
            Learn more...
          </a>
        </p>
      </div>
    </div>
    <div className="program-reverse">
      <h2>Support Healthy Relationships</h2>

      <div className="program-content">
        <img
          src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto/v1569522134/carloslopez/menasallies_ewvt3y.jpg"
          alt="support healthy relationships"
        />

        <p>
          As a member of the Austin/Travis County Family Violence Task Force,
          Carlos is dedicated to domestic violence awareness and education in
          the community. This year, in partnership with the Task Force, Carlos
          is co-chairing &quot;Men as Allies.&quot; With this group, he is
          focusing on education and awareness for men in the community.{' '}
          <a
            className="program-link"
            href="http://constable5.com/inf/Men-as-Allies.shtml"
            target="_blank"
            rel="noreferrer noopener"
          >
            Learn more...
          </a>
        </p>
      </div>
    </div>
    <div className="program">
      <h2>Disabled Parking Enforcement</h2>

      <div className="program-content">
        <img
          src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto/v1569604422/carloslopez/parking_wgrhag.jpg"
          alt="enforce disabled parking"
        />

        <p>
          Carlos was instrumental in helping establish a Citizen Disabled
          Parking Enforcement Program in 1995, a program which continues today,
          to ensure that disabled parking spaces would be available for those
          entitled to them. As Constable, he continues to be active in working
          for county-wide recognition of the need for equal access for our
          disabled community.{' '}
          <a
            className="program-link"
            href="http://constable5.com/inf/parking.shtml"
            target="_blank"
            rel="noreferrer noopener"
          >
            Learn more...
          </a>
        </p>
      </div>
    </div>

    <div className="program-reverse">
      <h2>Silver Watch Program</h2>

      <div className="program-content">
        <img
          src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto/v1569604422/carloslopez/silver_rasssx.png"
          alt="silver watch program"
        />

        <p>
          Keeping our most vulnerable and at-risk citizens safe is a priority
          for Carlos. He partnered with Meals On Wheels and More (MOW) to
          identify clients who have requested wellness checks and provide any
          information to the MOW staff for immediate action if necessary.{' '}
          <a
            className="program-link"
            href="https://www.fox7austin.com/news/crimewatch-precinct-5-constables-create-silver-watch"
            target="_blank"
            rel="noreferrer noopener"
          >
            Learn more...
          </a>
        </p>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
